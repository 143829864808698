<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="강사용 교재 수정"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>내/외부교재</th>
                                <td>
                                    <label><input type="radio" v-model="mod.info.btype" value="내부"><span class="ml-5 mr-20"> 내부교재</span></label>
                                    <label><input type="radio" v-model="mod.info.btype" value="외부"><span class="ml-5 mr-20"> 외부교재</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>교재명</th>
                                <td>
                                    <carrot-book-title v-if="mod.isLoaded" v-model:bookname.sync="mod.books.bookname" v-model="mod.books.idx_book" class="w-400px"></carrot-book-title>
                                </td>
                            </tr>
                            <tr>
                                <th>소속/강사명</th>
                                <td>
                                    <carrot-tutor v-if="mod.isLoaded" v-model:tutortype.sync="mod.info.tutortype" v-model:tutorname.sync="mod.info.tutorname" v-model="mod.info.idx_tutor" class="w-100per"></carrot-tutor>
                                </td>
                            </tr>
                            <tr>
                                <th>클래스{{ mod.info.tutortype }}</th>
                                <td>
                                    <select v-if="mod.isLoaded" :disabled="mod.info.tutortype=='CTM'" v-model="mod.info.idx_class" class="w-400px">
                                        <option value="">선택</option>
                                        <option v-for="(irow, i) in comp.classList" :key="i" :value="irow.idx">{{ irow.classname }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>출고희망일</th>
                                <td>
                                    <carrot-date-picker v-model="mod.info.request_date" class="w-110px"></carrot-date-picker>
                                    <span class="color-1 ml-10">※ 교재가 필요한 날짜를 선택하세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>금액</th>
                                <td>
                                    10,000원
                                    <span class="color-1 ml-10">※ 다음 달 10일 급여에서 공제됩니다.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(세부내용)</th>
                                <td>
                                    <textarea v-model.trim="mod.info.etc_memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotBookTitle from "@/components/common/CarrotBookTitle.vue"
import CarrotTutor from "@/components/common/CarrotTutor.vue"


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker,
        CarrotBookTitle,
        CarrotTutor
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();

        const comp = reactive({
            classList : [],

            doSearch : () => {
                if( mod.idx_tutor <= 0 ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                let params = {
                    tutortype : mod.info.tutortype,
                    tutor     : mod.info.idx_tutor
                };
                axios.get('/rest/component/getClassList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.classList = res.data.list;

                        mod.idx_class = "";
                        mod.classname = "";
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const mod = reactive({
            isLoaded  : false,
            utype_list : [],
            is_show    : true,

            idx  : 0,
            info : {
                btype             : "내부",
                idx_book          : 0,
                tutortype         : "ITM",
                idx_tutor         : 0,
                tutorname         : "",
                idx_class         : "",
                classname         : "",
                request_date      : "",
                etc_memo          : "",
            },
            books : [],

            doCancel : () => {
                router.push({ 
                    name:"ResourceManagement-TutorTextbookView-idx",
                    params:{
                        idx : mod.idx
                    }
                 });
            },

            doSubmit : () => {
                let params = {
                    idx : mod.idx,
                    btype        : mod.info.btype,
                    idx_book     : mod.info.idx_book,
                    tutortype    : mod.info.tutortype,
                    idx_tutor    : mod.info.idx_tutor,
                    tutorname    : mod.info.tutorname,
                    idx_class    : mod.info.idx_class,
                    request_date : mod.info.request_date,
                    etc_memo     : mod.info.etc_memo,
                };

                if( !params.btype ){
                    toast.error("교재 유형를 선택하세요.");
                    return;
                }
                if( params.idx_book <= 0 ){
                    toast.error("교재를 선택하세요.");
                    return;
                }
                if( !params.idx_tutor ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                if( params.tutortype == 'ITM' && params.idx_class <= 0 ){
                    toast.error("클래스를 선택하세요.");
                    return;
                }
                for(let i=0; i<comp.classList.length; i++){
                    let irow = comp.classList[i];

                    if( irow.idx == params.idx_class ){
                        params.classname = irow.classname;
                        break;
                    }
                }
                if( !params.request_date ){
                    toast.error("출고희망일을 선택하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/modTutorBookRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '강사용 교재 수정',
                            text : '수정하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-TutorTextbookView-idx',
                                params : {
                                    idx : mod.idx
                                }
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/resourceManagement/getBookRentalInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.books = mod.info.books[0];
                        mod.utype_list = mod.info.utype_list;

                        comp.doSearch();

                        mod.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        watch(() => mod.idx_tutor, (nVal, oVal) => {
            if( nVal > 0 && nVal != oVal ){
                comp.doSearch();
            }
        })

        onMounted(() => {
            // Mounted
            mod.isLoaded = false;

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.go(-1);
            } 

            mod.doSearch();
        });

        return {comp,mod};
    }
}
</script>

<style lang="scss" scoped>
</style>